export const DocumentType = Object.freeze({
  3: "common.energyCertificateLabel",
  4: "common.energyCertificateDocument",
  5: "common.IBIReceipt",
  6: "common.certificateOfHabitability",
  7: "common.writing",
  8: "common.photosAndPlan",
  10: "common.currentCertificateCommunityPayments",
  18: "common.powerOfAttorney",
  20: "common.ITE",
  21: "common.simpleNote",
  25: "common.usufruct",
  35: "common.rentalMandate",
  36: "common.certificateOfOccupancyRequest",
  37: "common.certificateCardOfHabitability",
  38: "common.leasingContract",
  39: "common.rentalsEquipmentInventory",
  40: "common.rentalsReferencePriceIndex",
  42: "common.supplies",
  71: "common.rentalReservationContract",
  79: "common.proofOfCurrentMonth",
  81: "common.electricityBill",
  82: "common.waterBill",
  83: "common.gasBill",
  84: "common.bailAgency",
  85: "common.deliveryOfKeys",
  86: "common.VPORental",
  87: "common.insurancePolicies",
  95: "common.bankDocumentWithCc",
  101: "common.proforma",
  114: "common.rentalReservationPaymentReceipt",
});
