export const ContractType = Object.freeze({
  rental_leasing_contract: "common.leasingContract",
  rental_reservation_contract: "common.rentalReservationContract",
  rental_leasing_waiver_contract: "common.rentalLeasingWaiverContract",
  rental_mandate: "common.rentalMandate",
});

export const ContractTypeKeys = Object.freeze({
  RENTAL_LEASING_CONTRACT: "rental_leasing_contract",
  RENTAL_LEASING_WAIVER_CONTRACT: "rental_leasing_waiver_contract",
});
