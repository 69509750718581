
import { Component, Vue } from "vue-property-decorator";
import { IBackOptions } from "@/models/BackOptions";
import { IAddress } from "@/models/Address";
import { IRentalLeasings, IRentalLeasing } from "@/models/RentalLeasing";
import {
  IDocuments,
  IDocumentFormatted,
  IDocumentFormattedLink,
  IDocument,
} from "@/models/Documents";
import { IPropertyInfo } from "@/models/PropertyInfo";
import { TabbarIds } from "@/enums/HousfyPage";
import { DocumentType } from "@/constants/DocumentType";
import { resolveRoutePath } from "@/router";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import DocumentsPageContent from "@/components/protected/DocumentsPageContent.vue";
import PropertyPageLayout from "@/components/layout/PropertyPageLayout.vue";
import ContactPerson from "@/components/ContactPerson.vue";
import DateUtils from "@/services/utils/DateUtils";
import ErrorService from "@/services/ErrorService";
import api from "@/api";
import ContactService from "@/services/ContactService";
import LeasingsService from "@/services/LeasingsService";
import { ContractType } from "@/constants/ContractType";
import { IHousfyError } from "@/models/HousfyError";
import { IOwnerPropertySignedContracts } from "@/models/OwnerProperties";

@Component({
  name: "DocumentsProperty",
  components: {
    HousfyPage,
    DocumentsPageContent,
    PropertyPageLayout,
    ContactPerson,
  },
})
export default class DocumentsProperty extends Vue {
  address: IAddress | null = null;
  propertyInfo: IPropertyInfo | null = null;
  leasings: IRentalLeasings | null = null;
  errorMessage: string | null = null;
  propertyDocuments: IDocuments | null = null;
  propertySignedContracts: IOwnerPropertySignedContracts | null = null;

  activeTabId = TabbarIds.ACTIVITY;
  loading = false;
  propertyUuid: string = this.$route.params.propertyUuid;

  async mounted() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;

    try {
      [
        this.address,
        this.propertyInfo,
        this.leasings,
        this.propertyDocuments,
        this.propertySignedContracts,
      ] = await Promise.all([
        api.property().retrieveAddress(this.propertyUuid),
        api.property().retrieveInfo(this.propertyUuid),
        api.property().retrieveLeasings(this.propertyUuid),
        api.propertyDocuments().retrieveProperty(this.propertyUuid),
        api.property().retrievePropertySignedContracts(this.propertyUuid),
      ]);
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error as IHousfyError);
    }

    this.loading = false;
  }

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get headerTitle(): string {
    return this.address?.fullAddress || "";
  }
  get currentLeasing(): IRentalLeasing | undefined {
    return LeasingsService.getCurrentLeasing(this.leasings?.items);
  }
  get showContactPerson(): boolean {
    return ContactService.shouldShowContactPerson(
      this.propertyInfo,
      this.currentLeasing
    );
  }
  get propertyDocumentsFormatted(): IDocumentFormatted[] {
    if (!this.propertyDocuments?.items) return [];

    return this.propertyDocuments?.items.map((doc) => {
      let title;
      if (this.$t(DocumentType[doc.type]))
        title = this.$t(DocumentType[doc.type]).toString();
      else {
        title = this.$t("common.documents").toString();
        const error = {
          message: `Missing lokalise key for document - DocumentType: ${doc.type}`,
        };
        ErrorService.handleGenericError(error);
      }
      const links = this.getDocumentLinks(doc);
      return {
        title,
        date: this.formatDate(doc.date),
        isDownload: links?.length === 1,
        links,
        tracking: `link_document_${title
          .toLocaleLowerCase()
          .replace(/\s/g, "-")}`,
      };
    });
  }

  get propertyContractsFormatted(): IDocumentFormatted[] {
    if (!this.propertySignedContracts) return [];
    const signedContracts = [
      {
        ...this.propertySignedContracts.lastRentalMandateSignedContract,
        type: "rental_mandate",
      },
    ];
    const contractsFormatted = signedContracts.map((contract) => {
      const dateToPrint = this.formatDate(contract.signedDate);

      return {
        title: this.$t((ContractType as any)[contract.type]).toString() || "",
        date: dateToPrint,
        dateToSort: contract.signedDate,
        isDownload: true,
        links: [
          {
            downloadUrl: contract.file?.downloadUrl,
            viewUrl: contract.file?.viewUrl,
          },
        ],
        tracking: `link_document_${contract.type
          .toLocaleLowerCase()
          .replace(/\s/g, "-")}`,
      };
    });
    return contractsFormatted;
  }

  get documentsOrdered(): IDocumentFormatted[] {
    return this.sortDocuments([
      ...this.propertyDocumentsFormatted,
      ...this.propertyContractsFormatted,
    ]);
  }

  formatDate(stringDate: string): string {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }
  sortDocuments(documents: IDocumentFormatted[]) {
    return documents.sort((a, b) => {
      const dateA = a?.date ? new Date(a?.date).getTime() : 0;
      const dateB = b?.date ? new Date(b?.date).getTime() : 0;
      return dateB - dateA;
    });
  }
  getDocumentLinks(document: IDocument): IDocumentFormattedLink[] {
    if (!document.files) return [];
    return document.files.map(({ downloadUrl, viewUrl }) => ({
      downloadUrl,
      viewUrl,
    }));
  }
}
