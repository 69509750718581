import { render, staticRenderFns } from "./PropertyPageLayout.vue?vue&type=template&id=64c6132c&scoped=true&"
import script from "./PropertyPageLayout.vue?vue&type=script&lang=ts&"
export * from "./PropertyPageLayout.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyPageLayout.vue?vue&type=style&index=0&id=64c6132c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c6132c",
  null
  
)

export default component.exports