
import { Component, Prop, Vue } from "vue-property-decorator";
import DocumentCardShimmer from "@/components/DocumentCardShimmer.vue";

@Component({
  name: "DocumentsMainDocumentsShimmer",
  components: {
    DocumentCardShimmer,
  },
})
export default class DocumentsMainDocumentsShimmer extends Vue {
  @Prop({ type: Boolean, default: "" })
  hasSubtitle?: boolean;
}
