
import { Component, Vue, Prop } from "vue-property-decorator";
import ContactPersonShimmer from "@/components/ContactPersonShimmer.vue";
import { Avatar } from "housfy-ui-lib";
import RatingStars from "@/components/ui/RatingStars.vue";
import CallButton from "@/components/CallButton.vue";
import ContactButton from "@/components/ContactButton.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyAgent } from "@/models/HousfyAgent";
import { IHousfyError } from "@/models/HousfyError";

@Component({
  name: "ContactPerson",
  components: {
    ContactPersonShimmer,
    Avatar,
    RatingStars,
    CallButton,
    ContactButton,
    HousfySvg,
  },
})
export default class ContactPerson extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;

  @Prop({ type: Boolean, default: false })
  pageIsLoading!: boolean;

  contactPerson: IHousfyAgent | null = null;

  loading = false;
  maxRating = 4.5;

  get whatsappNumber(): string {
    return this.contactPerson?.whatsappAvailable
      ? this.contactPerson?.phone?.replace("+", "")
      : "";
  }

  get showContact(): boolean {
    return !!(
      this.contactPerson?.name &&
      (this.contactPerson?.phone || this.contactPerson?.email)
    );
  }

  async mounted() {
    try {
      this.loading = true;
      this.contactPerson = await api
        .property()
        .retrieveHousfyAgent(this.propertyUuid);
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    }

    this.loading = false;
  }
}
