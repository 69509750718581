
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyButton } from "housfy-ui-lib";

@Component({
  name: "ContactButton",
  components: { HousfySvg, HousfyButton },
})
export default class WhatsappButton extends Vue {
  @Prop({ type: String, default: "" })
  mail?: string;
  @Prop({ type: String, default: "" })
  whatsappNumber?: string;

  handleButtonClick() {
    if (this.whatsappNumber) {
      /* eslint-disable-next-line xss/no-location-href-assign */
      window.open(`https://api.whatsapp.com/send?phone=${this.whatsappNumber}`);
    } else {
      /* eslint-disable-next-line xss/no-location-href-assign */
      location.href = `mailto:${this.mail}`;
    }
  }
}
