import * as Sentry from "@sentry/browser";
import { IHousfyError } from "@/models/HousfyError";
import { IRNTLeadPayload } from "@/models/IRNTLeadPayload";

export default class ErrorService {
  static handleLeadGenerationError(
    error: IHousfyError,
    payload: IRNTLeadPayload | any
  ) {
    const message = error?.message || JSON.stringify(error);

    if (!error?.code || !!error?.error?.validations)
      console.error("Error generating lead", message, { error, payload });

    Sentry.captureException("Error generating lead: " + message, {
      extra: { error, payload },
    });
  }

  static handleGenericError(error: IHousfyError) {
    const message = error?.message || JSON.stringify(error);

    console.error("Error", message, { error });

    Sentry.captureException("Error: " + message, {
      extra: { error },
    });
  }

  static getErrorFromCode(error: IHousfyError) {
    switch (error?.status) {
      case 403:
        return "common.youDontHavePermissionsToAccessThisPage";
      case 404:
        return "common.thisPageDoesntExist";
      case 500:
        this.handleGenericError(error);
        return "common.thereWasAnErrorPleaseTryAgainLater";
      default:
        return "common.thereWasAnErrorPleaseTryAgainLater";
    }
  }
}
