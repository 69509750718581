import { PropertyStatus } from "@/enums/PropertyStatus";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import { IPropertyInfo } from "@/models/PropertyInfo";
import {
  IRentalLeasing,
  IRentalLeasingFormatted,
} from "@/models/RentalLeasing";

class ContactService {
  shouldShowContactPerson(
    propertyInfo: IPropertyInfo | null,
    currentLeasing: IRentalLeasing | IRentalLeasingFormatted | null | undefined
  ): boolean {
    const isRentalManagement =
      propertyInfo?.propertyStatus === PropertyStatus.RENTAL_MANAGEMENT;
    const leasingStatusWon = currentLeasing?.status === RentalLeasingStatus.WON;
    const isLost = propertyInfo?.propertyStatus === PropertyStatus.LOST;
    const isLostNotPublished =
      propertyInfo?.propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED;

    return (
      !(isRentalManagement && leasingStatusWon) &&
      !isLost &&
      !isLostNotPublished
    );
  }
}

export default new ContactService();
