import { IDate } from "@/models/Date";
import { IDateDiffInterval } from "@/models/DateDiffInterval";
class DateUtils {
  private calculateDiffDays(priorDate: string): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const changeDate = new Date(priorDate);
    const today = new Date();
    return Math.round((changeDate.valueOf() - today.valueOf()) / oneDay);
  }
  daysFromToday(priorDate: string | null | undefined): number | null {
    return priorDate ? this.calculateDiffDays(priorDate) : null;
  }
  dateDiffInterval(priorDate: string | undefined): IDateDiffInterval | null {
    if (priorDate) {
      const diffDays = Math.abs(this.calculateDiffDays(priorDate));

      const days = Math.floor(diffDays);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years) return { type: "year", value: years };
      if (months) return { type: "month", value: months };
      return { type: "day", value: days };
    }
    return null;
  }
  formatDate(stringDate: string): IDate {
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = date
      .toLocaleString("en-GB", {
        month: "long",
      })
      .toLowerCase();
    const year = date.getFullYear();
    return { day, month, year };
  }
}
export default new DateUtils();
