
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";

@Component({
  name: "RatingStars",
  components: { HousfySvg },
})
export default class RatingStars extends Vue {
  @Prop({ type: Number, default: 5 })
  max!: number;
  @Prop({ type: Number, default: 5 })
  value!: number;
  @Prop({ type: String, default: "#11AEFA" })
  color!: string;

  get percentage() {
    return `${(this.value * 100) / this.max}%`;
  }
}
