import { IRentalLeasing } from "@/models/RentalLeasing";
import { IDocumentsLeasing } from "@/models/Documents";
import DateUtils from "./utils/DateUtils";

class LeasingsService {
  isCurrentLeasing(leasing: IRentalLeasing | IDocumentsLeasing): boolean {
    const validityDaysFromToday = DateUtils.daysFromToday(
      leasing.leasingContractValidityDate
    );
    const terminationDaysFromToday = DateUtils.daysFromToday(
      leasing.leasingTerminationDate
    );
    const hasCurrentOrPastValidityDate = !!(
      validityDaysFromToday && validityDaysFromToday <= 0
    );
    const hasCurrentOrFutureOrUndefinedTerminationDate = !!(
      !leasing.leasingTerminationDate ||
      (terminationDaysFromToday && terminationDaysFromToday >= 0)
    );
    return (
      hasCurrentOrPastValidityDate &&
      hasCurrentOrFutureOrUndefinedTerminationDate
    );
  }
  getCurrentLeasing(
    leasings: IRentalLeasing[] = []
  ): IRentalLeasing | undefined {
    return leasings.find(this.isCurrentLeasing);
  }
  getPastLeasings(leasings: IRentalLeasing[] = []): IRentalLeasing[] {
    return leasings.filter((leasing) => {
      const daysFromToday = DateUtils.daysFromToday(
        leasing.leasingTerminationDate
      );
      return daysFromToday && daysFromToday < 0;
    });
  }
  sortLeasings(leasings: IRentalLeasing[] = []): IRentalLeasing[] {
    return leasings.sort((a, b) => {
      const dateA = a?.leasingContractValidityDate
        ? new Date(a?.leasingContractValidityDate).getTime()
        : 0;
      const dateB = b?.leasingContractValidityDate
        ? new Date(b?.leasingContractValidityDate).getTime()
        : 0;
      return dateB - dateA;
    });
  }
}

export default new LeasingsService();
