
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyButton } from "housfy-ui-lib";

@Component({
  name: "CallButton",
  components: { HousfySvg, HousfyButton },
})
export default class CallButton extends Vue {
  @Prop({ type: String, required: true })
  internationalPhone?: string;

  handleButtonClick() {
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = `tel:${this.internationalPhone}`;
  }
}
