
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import * as Sentry from "@sentry/browser";

@Component
export default class HousfySvg extends Vue {
  @Prop({ type: String, required: true })
  private svg!: string;

  @Prop({ type: String, default: null })
  private width?: string;

  @Prop({ type: String, default: "1em" })
  private height?: string;

  @Prop({ type: String, default: "" })
  private svgClass?: string;

  protected svgInline: any = null;

  protected async loadSvg() {
    try {
      const svgImported = await import(`@/assets/svg/${this.svg}.svg`);
      this.svgInline = svgImported?.default;
    } catch (error) {
      console.error("Importing SVG");
      Sentry.captureException("Importing SVG: " + error);
    }
  }

  @Watch("svg", { immediate: true })
  protected handler() {
    this.loadSvg();
  }

  @Emit("click")
  protected click() {}
}
